.estimated-outfit-cost {
  max-width: 738px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  display: flex;
  border-bottom: 3px solid #fff;
  padding: 30px 15px 15px 15px;
  align-items: flex-end;

  .label {
    font-family: GothamLight, sans-serif;
    font-size: 12.13px;

    strong {
      display: block;
      font-family: GothamBold, sans-serif;
    }
  }

  .cost {
    color: var(--markhams-link-color-active);
    font-family: GothamBold, sans-serif;
    flex: 1;
    text-align: center;
    font-size: 20px;
    margin-left: -100px;
  }
}
